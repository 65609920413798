import { Injectable } from '@angular/core';
// var CryptoJS = require("crypto-js");
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class UtilityserviceService {
  constructor() {}

  passkey = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  public Plaintextencryption(key: string, value: string): string {
    if (key === null) {
      return null;
    }
    if (value === null) {
      return null;
    }
    const ciphertext = CryptoJS.AES.encrypt(value, this.passkey).toString();
    return ciphertext;
  }

  public PlaintextDecryption(key: string, encryptedValue: string): string {
    if (key === null) {
      return null;
    }
    if (encryptedValue === null) {
      return null;
    }
    var bytes = CryptoJS.AES.decrypt(encryptedValue, this.passkey);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }

  public Objectencryption(Inputdata: any): any {
    if (Inputdata === null || Inputdata === undefined) {
      return null;
    }
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(Inputdata),
      this.passkey
    ).toString();
    return ciphertext;
  }

  public ObjectDecryption(EncryptedObject: any): any {
    if (EncryptedObject === null || EncryptedObject === undefined) {
      return null;
    }
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(EncryptedObject, this.passkey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
}
