import { Component, OnInit } from '@angular/core';
import { ApplicationServiceService } from '../Services/application-service.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PlanLineItem, SIMPlanDetail } from '../Models/simplan-detail';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-appfooter',
  templateUrl: './appfooter.component.html',
  styleUrls: ['./appfooter.component.scss'],
})
export class AppfooterComponent implements OnInit {
  configUrl = 'assets/config.json';
  public SIMNumber: string = 'kamal';
  public GetSIMPlanDetail: SIMPlanDetail;

  constructor(public _ApplicationService: ApplicationServiceService) {}

  ngOnInit(): void {
    // this.GetSIMPlanDetails();
  }

  // Get SIM Plan Details
  // GetSIMPlanDetails() {
  //   var SIMNumber = '1234556645545';
  //   this._ApplicationService.GetSIMPlan(SIMNumber).subscribe(((ruselt) => {
  //     this.GetSIMPlanDetail = ruselt;
  //     console.log(this.GetSIMPlanDetail);
  //   }));

  // }
}
