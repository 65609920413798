<header class="fixed-top border-bottom bg-canada text-white text-center">
  <div class="container">
    <nav class="navbar navbar-expand-md navbar-dark py-0">
      <div class="container-fluid">
        <a class="navbar-brand canada-logo" href="/">
          <h2 class="text-logoText mb-3 f-italic fw-6">Koodo</h2>
          <h2 class="text-logo mb-0 f-italic fw-6">India Offer</h2>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse border-top border-top-md-0 f-italic" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link fw-6 active" aria-current="page" href="#"> <img
                  src="../../assets/AppImages/cell-phone.svg" alt="Uniconnect" height="30" width="30"> 9:30 AM to 6:00
                PM Support</a>
            </li>
          </ul>
          <div class="rightnav col-md-4 text-md-end">
            <div class="contactlinks d-inline-block text-md-start">
              <a href="tel:+919967106808" class="nav-item">
                <i class="bi bi-whatsapp"></i>
                <img src="../../assets/AppImages/phone-call.svg" alt="Uniconnect" height="30" width="30">
                +91 99671 06808 
              </a>
              <a class="nav-item"
                href="https://api.whatsapp.com/send?phone=+919967106808&text=Lets%20talk%20to%20koodoindiaoffer!"
                target="_blank">
                <i class="bi bi-whatsapp"></i>
                <img src="../../assets/AppImages/whatsapp.svg" alt="Uniconnect" height="30" width="30">
                +91 99671 06808
              </a>
              <a class="nav-item" href="mailo:info@koodoindiaoffer.com"><img height="27" width="27"
                  src="../../assets/AppImages/email.svg">info@koodoindiaoffer.com</a>
            </div>

          </div>
        </div>
      </div>
    </nav>
  </div>
</header>




