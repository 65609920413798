export class SIMRegistrationSec {
  SImNumber: string;
  PlanID: number;
  SIMRegistrationID: string;
  id: number;
  DateofTravel?: Date;
  DateofTravelTentative: string;
  EmailID: string;
  SourceName: string = 'Koodo-Prepaid';
}

export class SIMRegistrationAddress {
  SImNumber: string;
  PlanID: number;
  SIMRegistrationID: string;
  id: number;
  DateofTravel?: Date;
  DateofTravelTentative: string;
  EmailID: string;
  SourceName: string = 'Koodo-Prepaid';
  Canadianaddress: string;
  Province: string;
  PostalCode: string;
  UniversityName: string;
  HandsetModelnumber: string;
  passportfilename: string;
  passportbackfilename: string;
  visafilename: string;
}
