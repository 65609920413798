import { Component, OnInit } from '@angular/core';
import { SIMRegistration } from '../Models/simregistration';
import { SIMRegistrationFinal } from '../Models/simregistration-final';
import { ApplicationServiceService } from '../Services/application-service.service';
import { FormsModule, FormArray, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { SIMRegistrationResponse, UserFiles } from '../Models/SIMRegistrationResponse';
import { DatePipe, CommonModule } from '@angular/common'
import { FileUploadServiceService } from '../FileService/file-upload-service.service';
import { FileUploadModel } from '../Models/FileUploadModel';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { Expectedperiod } from '../Models/Expectedperiod';

@Component({
  selector: 'app-sim-card-registration-final',
  templateUrl: './sim-card-registration-final.component.html',
  styleUrls: ['./sim-card-registration-final.component.scss']
})
export class SImCardRegistrationFinalComponent implements OnInit {
  public RegistrationID: string;
  public SIMRegistrationFinalModel: SIMRegistrationFinal;
  public SIMRegistrationResponseModel: SIMRegistrationResponse;
  public FileUploadModel: FileUploadModel;
  public Startdate: Date;
  submitted: boolean = false;
  public ProvinceList: any = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Northwest Territories",
    "Nova Scotia",
    "Nunavut",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
    "Yukon"
  ]


  public VisaStatusList: any = [
    "Select",
    "Student Visa",
    "Work Visa",
    "Visitor Visa",
    "Any Other"
  ]

  public Expectedperiod: Expectedperiod[];

  constructor(
    dateAdapter: DateAdapter<NativeDateAdapter>,
    public ApplicationServiceService: ApplicationServiceService,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    public FileUploadServiceService: FileUploadServiceService,
    public Router: Router
  ) {
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );

    dateAdapter.setLocale('en-GB');
  }
  ngOnInit(): void {
    this.Startdate = new Date();
    this.SIMRegistrationFinalModel = new SIMRegistrationFinal();
    this.SIMRegistrationFinalModel.Province = 'Alberta';
    this.SIMRegistrationFinalModel.VisaStatus = "Select";
    this.SIMRegistrationFinalModel.DateofTravelTentative = 'Confirmed';
    this.SIMRegistrationFinalModel.CanadianVisaDocumentNumber = "less than 30 days";
    this.SIMRegistrationResponseModel = new SIMRegistrationResponse();
    this.DisplayBindAutoFillData();
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );
    this.GetExpectedperiod();
  }

  DisplayBindAutoFillData() {
    this.ApplicationServiceService.GetSIMRegistrationDetail(this.RegistrationID).subscribe((Result: any) => {
      this.SIMRegistrationResponseModel = JSON.parse(Result);
      // this.SIMRegistrationFinalModel.TravelDateReConfirm = this.SIMRegistrationResponseModel.DateofTravel;
      //this.SIMRegistrationFinalModel.ExpiryDateofVIsaDocument = this.SIMRegistrationResponseModel.ExpiryDateofVIsaDocument;
    });
  }

  handleFileInputPassportFront(files: FileList) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = "0";
    this.FileUploadModel.uploadtype = "PassportFront";
    this.FileUploadServiceService.PostFile(this.FileUploadModel).subscribe((result: any) => {
      //console.log(result);
    });
  }
  handleFileInputPasspoerBack(files: FileList) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = "0";
    this.FileUploadModel.uploadtype = "PassportBack";
    this.FileUploadServiceService.PostFile(this.FileUploadModel).subscribe((result: any) => {

    });
  }

  handleFileInputVisa(files: FileList) {
    this.FileUploadModel = new FileUploadModel();
    this.FileUploadModel.fileToUpload = files.item(0);
    this.FileUploadModel.SIMRegistrationID = this.RegistrationID;
    this.FileUploadModel.id = "0";
    this.FileUploadModel.uploadtype = "Visa";
    this.FileUploadServiceService.PostFile(this.FileUploadModel).subscribe((result: any) => {
    });
  }

  OnSubmit(SIMRegistrationFinalModel: SIMRegistrationFinal) {
    this.submitted = true;
    if (SIMRegistrationFinalModel.VisaStatus == "Select") {
      alert('Please select the Visa status.!');
      return;
    }
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );

    SIMRegistrationFinalModel.SIMRegistrationID = this.RegistrationID;
    SIMRegistrationFinalModel.ExpiryDateofVIsaDocument = null;
    this.ApplicationServiceService.AddSIMRegistrationFinal(SIMRegistrationFinalModel, this.RegistrationID).subscribe((result: any) => {
      this.route.params.subscribe((params) =>
        this.RegistrationID = params['RegistrationID']
      );
      this.Router.navigate(['/Thankyou/' + this.RegistrationID]);
    });
  }

  OnBackButton() {
    this.route.params.subscribe((params) =>
      this.RegistrationID = params['RegistrationID']
    );
    this.Router.navigate(['/SIMCardRegistrationCont/' + this.RegistrationID + '/Next']);
    // this.Router.navigateByUrl('/SIMCardRegistration/' + this.RegistrationID);
  };

  GetExpectedperiod() {
    this.ApplicationServiceService.GetExpectedperiods().subscribe((result: any) => {
      this.Expectedperiod = result;
    });
  }

}
