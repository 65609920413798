<footer class="bd-footer py-lg-4 my-lg-4 fixed-bottom">
<div class="container">
<div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">

<div class="row">
<div class="col-md-3">


  
    <div class="textbold"> 
      <a href="/#" target="_parent">Home</a>   
  
  </div>

</div>

<div class="col-md-5">
  
    <div class="textbold"> 
      <a href="#/privacy-policy" target="_self">Privacy & Payment Policy</a>   
    </div>
  
</div>

<div class="col-md-4">
  
    <div class="textbold"> 
      <a href="../../assets/Privacy/PRIVACY AND PAYMENT POLICY.pdf" download="PRIVACY AND PAYMENT POLICY.pdf" target="_blank">Download Privacy & Payment Policy</a>   
    </div>
 
</div>


</div>

 
</div>
</div>
  </footer>
