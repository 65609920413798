import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customercarelogin',
  templateUrl: './customercarelogin.component.html',
  styleUrls: ['./customercarelogin.component.scss'],
})
export class CustomercareloginComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
