<div class="container">
  <form #f="ngForm" id="Finalfrm" novalidate>
    <div class="row">
      <div class="col-md-10 offset-md-1 shadow-lg px-md-5 py-3 border bg-white">
        <p class="text-center innerheader mb-0">The plan will be made active 48 hours prior to your departure to Canada
        </p>
        <p class="text-center innerheader">
          Once plan is made active a phone number will be shared over email
        </p>
        <p class="fs-13 mb-md-4 textbold">Address in Canada<sup class="text-danger">*</sup></p>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="Line1" class="col-form-label textbold">Line 1</label>
          </div>
          <div class="col-md-5">
            <!-- {{f.value | json}}
              {{Line1?.invalid}}
              {{Line1?.dirty}}
              {{Line1?.touched}} -->
            <input type="text" class="form-control first-letter" id="Line1" name="Line1" [ngClass]="{
              'is-invalid': Line1?.invalid,
              'valid': !Line1?.valid && (Line1?.dirty || Line1?.touched)
         }" [(ngModel)]="SIMRegistrationFinalModel.Line1" #Line1="ngModel" required>
            <div *ngIf="!Line1?.valid && (Line1?.dirty || Line1?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="line2" class="col-form-label textbold">Line 2</label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control first-letter" id="Line2" name="Line2" [ngClass]="{
              'is-invalid': Line2?.invalid,
              'valid': Line2?.valid
         }" [(ngModel)]="SIMRegistrationFinalModel.Line2" #Line2="ngModel" required>

            <div *ngIf="!Line2?.valid && (Line2?.dirty || Line2?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="city" class="col-form-label textbold">City</label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control first-letter" id="city" name="city" [ngClass]="{
                'is-invalid': City?.invalid,
                'valid': !City?.valid && (City?.dirty || City?.touched)
           }" [(ngModel)]="SIMRegistrationFinalModel.City" #City="ngModel" required>
            <div *ngIf="!City?.valid && (City?.dirty || City?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="Zipcode" class="col-form-label textbold">Zipcode</label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control first-letter" id="Zipcode" name="Zipcode"
              [(ngModel)]="SIMRegistrationFinalModel.Zipcode" #Zipcode="ngModel" required [ngClass]="{
                'is-invalid': Zipcode?.invalid,
                'valid': !Zipcode?.valid && (Zipcode?.dirty || Zipcode?.touched)
           }">



            <div *ngIf="!Zipcode?.valid && (Zipcode?.dirty || Zipcode?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="province" class="col-form-label textbold">Province</label>
          </div>
          <div class="col-md-5">

            <select class="form-select" aria-label="Default select example" [ngClass]="{
              'is-invalid': Province?.invalid,
              'valid': !Province?.valid && (Province?.dirty || Province?.touched)
         }" [(ngModel)]="SIMRegistrationFinalModel.Province" name="Province" id="Province" #Province="ngModel">
              <option *ngFor="let Province of ProvinceList" [ngValue]="Province" [selected]="Province === Alberta">
                {{Province}}
              </option>
            </select>
            <div *ngIf="!Province?.valid && (Province?.dirty || Province?.touched)">
              Required
            </div>
          </div>
        </div>

        <div class="row mb-4 align-items-center">
          <div class="col-md-4">
            <label for="email" class="col-form-label textbold">Email ID to received invoice<sup
                class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">
            <input type="email" class="form-control" id="EmailID" aria-label="email"
              [(ngModel)]="SIMRegistrationFinalModel.EmailID" required name="EmailID" #EmailID="ngModel" [ngClass]="{
                'is-invalid': EmailID?.invalid,
                'valid': !EmailID?.valid && (EmailID?.dirty || EmailID?.touched)
           }">


            <div *ngIf="!EmailID?.valid && (EmailID?.dirty || EmailID?.touched)">
              Email ID
            </div>


          </div>
        </div>

        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="VisaStatus" class="col-form-label textbold">Visa Status
              <sup class="text-danger">*</sup>

            </label>
          </div>
          <div class="col-md-5">

            <select class="form-select" aria-label="Default select example"
              [(ngModel)]="SIMRegistrationFinalModel.VisaStatus" name="VisaStatus" id="VisaStatus" #VisaStatus="ngModel"
              [ngClass]="{
                'is-invalid': VisaStatus?.invalid,
                'valid': !VisaStatus?.valid && (VisaStatus?.dirty || VisaStatus?.touched)
           }">
              <option *ngFor="let VisaItem of VisaStatusList" [ngValue]="VisaItem" [selected]="VisaItem == 'Select'">
                {{VisaItem}}
              </option>
            </select>

            <div *ngIf="!VisaStatus?.valid && (VisaStatus?.dirty || VisaStatus?.touched)">
              Required
            </div>
          </div>
        </div>


        <div class="row mb-md-4 align-items-center" *ngIf="SIMRegistrationFinalModel.VisaStatus === 'Visitor Visa'">
          <div class="col-md-4">
            <label for="VisaStatus" class="col-form-label textbold">Expected period of usage
              <sup class="text-danger">*</sup>

            </label>
          </div>
          <div class="col-md-5">

            <select class="form-select" aria-label="Default select example"
              [(ngModel)]="SIMRegistrationFinalModel.CanadianVisaDocumentNumber" name="CanadianVisaDocumentNumber"
              id="CanadianVisaDocumentNumber" #CanadianVisaDocumentNumber="ngModel"
               [ngClass]="{
                'is-invalid': CanadianVisaDocumentNumber?.invalid,
                'valid': !CanadianVisaDocumentNumber?.valid && (CanadianVisaDocumentNumber?.dirty || CanadianVisaDocumentNumber?.touched)
           }" required>
              <option *ngFor="let ExItem of Expectedperiod" [ngValue]="ExItem.name"
                [selected]="ExItem.name == 'less than 30 days'">
                {{ExItem.name}}
              </option>
            </select>

            <div
              *ngIf="!CanadianVisaDocumentNumber?.valid && (CanadianVisaDocumentNumber?.dirty || CanadianVisaDocumentNumber?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center" *ngIf="SIMRegistrationFinalModel.VisaStatus === 'Any Other'">
          <div class="col-md-4">
            <label for="anyothervisa" class="col-form-label textbold">Any other Visa<sup
                class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">

            <input type="text" class="form-control first-letter" id="OtherVisaValue" name="OtherVisaValue"
              [(ngModel)]="SIMRegistrationFinalModel.OtherVisaValue" #OtherVisaValue="ngModel" required [ngClass]="{
                'is-invalid': OtherVisaValue?.invalid,
                'valid': !OtherVisaValue?.valid && (OtherVisaValue?.dirty || OtherVisaValue?.touched)
           }">

            <div *ngIf="!OtherVisaValue?.valid && (OtherVisaValue?.dirty || OtherVisaValue?.touched)">
              Required
            </div>
          </div>
        </div>

        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="dob" class="col-form-label textbold">Date of Birth<sup class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">
            <div class="input-group">
              <input placeholder="Date of Birth" matInput [matDatepicker]="pickerdob" id="DateofBirth"
                name="DateofBirth" class="form-control" [(ngModel)]="SIMRegistrationFinalModel.DateofBirth"
                #DateofBirth="ngModel" #DateofBirth="ngModel" required [ngClass]="{
                  'is-invalid': DateofBirth?.invalid,
                  'valid': !DateofBirth?.valid && (DateofBirth?.dirty || DateofBirth?.touched)
             }">
              <mat-datepicker-toggle matSuffix [for]="pickerdob"></mat-datepicker-toggle>
              <mat-datepicker #pickerdob></mat-datepicker>
            </div>
            <div *ngIf="!DateofBirth?.valid && (DateofBirth?.dirty || DateofBirth?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-4 align-items-center">
          <div class="col-md-4">
            <label for="date" class="col-form-label textbold">Arrival Date<sup class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div clas="col-md-5">

                <div class="input-group">
                  <input placeholder="Date of Travel" matInput [matDatepicker]="pickerdot" id="Traveldate"
                    name="Traveldate" class="form-control" [(ngModel)]="SIMRegistrationFinalModel.DateofTravel"
                    #Traveldate="ngModel" #Traveldate="ngModel" required [min]="Startdate" [ngClass]="{
                      'is-invalid': Traveldate?.invalid,
                      'valid': !Traveldate?.valid && (Traveldate?.dirty || Traveldate?.touched)
                 }">
                  <mat-datepicker-toggle matSuffix [for]="pickerdot"></mat-datepicker-toggle>
                  <mat-datepicker #pickerdot></mat-datepicker>
                </div>
              </div>
            </div>
            <div *ngIf="!Traveldate?.valid && (Traveldate?.dirty || Traveldate?.touched)">
              Arrival Date
            </div>
          </div>
        </div>
        <div class="row mb-4 align-items-center">
          <div class="col-md-4">
            <label for="tdate" class="col-form-label textbold">Arrival Date<sup class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">
            <select class="form-select" aria-label="Default select example"
              [(ngModel)]="SIMRegistrationFinalModel.DateofTravelTentative" name="DateofTravelTentative"
              id="DateofTravelTentative" #DateofTravelTentative="ngModel" [ngClass]="{
                'is-invalid': DateofTravelTentative?.invalid,
                'valid': !DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)
           }">
              <option selected>Confirmed</option>
              <option>Tentative</option>
            </select>
            <div
              *ngIf="!DateofTravelTentative?.valid && (DateofTravelTentative?.dirty || DateofTravelTentative?.touched)">
              Arrival Date
            </div>
          </div>
        </div>

        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="uniname" class="col-form-label textbold">University Name</label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control first-letter" id="uniname" name="uniname"
              [(ngModel)]="SIMRegistrationFinalModel.StudyingUniversityName" #StudyingUniversityName="ngModel">
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="UCCID" class="col-form-label textbold">Study / Work Permit No. (UCI No.)<sup
                class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control" id="UCCID" name="UCCID"
              [(ngModel)]="SIMRegistrationFinalModel.UCCID" #UCCID="ngModel" [ngClass]="{
                'is-invalid': UCCID?.invalid,
                'border-color: green': !UCCID?.valid && (UCCID?.dirty || UCCID?.touched)
           }" required>
            <div *ngIf="!UCCID?.valid && (UCCID?.dirty || UCCID?.touched)">
              Study / Work Permit No. (UCI No.) Required
            </div>
          </div>
        </div>

        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="tdr" class="col-form-label textbold">Permit Expiry Date<sup class="text-danger">*</sup></label>
          </div>
          <div class="col-md-5">
            <div class="input-group">
              <input placeholder="Permit Expiry Date" matInput [matDatepicker]="picker" id="TravelDateReConfirm"
                name="TravelDateReConfirm" class="form-control"
                [(ngModel)]="SIMRegistrationFinalModel.TravelDateReConfirm" #TravelDateReConfirm="ngModel"
                [min]="Startdate" [ngClass]="{
                  'is-invalid': TravelDateReConfirm?.invalid,
                  'border-color: green': !TravelDateReConfirm?.valid && (TravelDateReConfirm?.dirty || TravelDateReConfirm?.touched)
             }" required>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
        </div>

        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="imei" class="col-form-label pb-0 textbold">IMEI number of your phone</label><br>
            <small>(get it by entering *#06# on your phone)</small>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control" id="imei" name="imei"
              [(ngModel)]="SIMRegistrationFinalModel.IMEINumber" #IMEINumber="ngModel">
          </div>
        </div>

        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="tdr" class="col-form-label textbold">Passport copy</label>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <label for="formFile" class="form-label textbold"><b> Front/Back page upload</b></label>
                <input class="form-control" placeholder="Front" type="file" id="formPFFile" name="formPFFile"
                  accept="image/png, image/gif, image/jpeg, .pdg, .PDF"
                  (change)="handleFileInputPassportFront($event.target.files)">
              </div>
              <div class="col-md-6">
                <label for="formFile" class="form-label textbold"><b> Back page upload</b></label>
                <input class="form-control" placeholder="Front" type="file" id="formPbackFile" name="formPbackFile"
                  accept="image/png, image/gif, image/jpeg, .pdg, .PDF"
                  (change)="handleFileInputPasspoerBack($event.target.files)">
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
            <label for="visa" class="col-form-label textbold">Visa copy</label>
          </div>
          <div class="col-md-8">
            <input class="form-control" type="file" id="visa" name="visa"
              accept="image/png, image/gif, image/jpeg, .pdg,.PDF" (change)="handleFileInputVisa($event.target.files)">
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4 d-none d-md-block">
            &nbsp;
          </div>
          <div class="col-md-8">
            <input type="checkbox" id="Customeracceptance" name="Customeracceptance" value="Bike"
              [(ngModel)]="SIMRegistrationFinalModel.Customeracceptance" #Customeracceptance="ngModel" required
              [ngClass]="{
                'is-invalid': Customeracceptance?.invalid,
                'valid': !Customeracceptance?.valid && (Customeracceptance?.dirty || Customeracceptance?.touched)
           }">
            <label for="Customeracceptance"><b> &nbsp; &nbsp; Please confirm your acceptance</b></label><br>
            <div *ngIf="!Customeracceptance?.valid && (Customeracceptance?.dirty || Customeracceptance?.touched)">
              Required
            </div>
          </div>
        </div>
        <div class="row mb-md-4 align-items-center">
          <div class="col-md-4">
          </div>
          <div class="col-md-8 text-end">
            <button type="submit" class="btn btn-danger" (click)="OnBackButton()"> &lt; Previous</button> &nbsp; &nbsp;
            <!-- {{Finalfrm.invalid}} -->
            <!-- [disabled]="Finalfrm.invalid" -->
            <button type="submit" class="btn btn-danger" [disabled]="f.invalid"
              (click)="OnSubmit(SIMRegistrationFinalModel)">Submit &gt;</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>