export class SIMRegistrationFinal {
  SIMRegistrationID: string;
  id?: number;
  Line1: string;
  Line2: string;
  City: string;
  Zipcode: string;
  Province: string;
  DateofBirth?: Date;
  StudyingUniversityName: string;
  ProvinceState: string;
  UCCID: string;
  IMEINumber: string;
  TravelDateReConfirm?: Date;
  ExpiryDateofVIsaDocument?: Date;
  Customeracceptance?: boolean;
  DateofTravel?: Date;
  DateofTravelTentative: string;
  VisaStatus: string;
  OtherVisaValue: string;
  EmailID: string;
  CanadianVisaDocumentNumber: string;
}
