import { NgModule } from '@angular/core';
import { MatDatepickerModule 
  } from '@angular/material/datepicker';
import {FormsModule} from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from
  '@angular/material/button';
import { MatButtonToggleModule } from
  '@angular/material/button-toggle';
import { MatInputModule } from
  '@angular/material/input';
import { MatFormFieldModule } from
  '@angular/material/form-field';
import { MatNativeDateModule , } from
  '@angular/material/core';
import {TransformStringFilter} from './Services/TransformFilter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';   

@NgModule({
  imports: [
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    MatDatepickerModule
  ],
  providers:[{ provide: MAT_DATE_LOCALE, useValue: { useUtc: 'en-GB' } }]
})

export class MaterialModule {}