<div class="container-fluid">
    <div class="text-center">
        <p>
            Hi <b> {{SIMRegistrationResponseModel.FirstName}} </b>, your SIM Card 
            <b> {{SIMRegistrationResponseModel.SIMCardNumberICCID}} </b> has been successfully registered
            <br>
            You will soon be receiving a call, confirming all information required for your SIM activation.
            <br>
            Keep the happiness rolling by renewing your Koodo SIM with $40 - 30GB plan. 
            <br>
            To activate please register for your prepaid self-serve account at <a href="https://prepaidselfserve.koodomobile.com/en/koodo/create-account">https://prepaidselfserve.koodomobile.com/</a>,before your Koodo first month is over to get the offer.
        </p>
        <div>
        </div>
        <p>
            <a class="button" href="https://www.koodoindiaoffer.com/">Click here to register another SIM Card</a>
        </p>
    </div>
    <br>
    <br>
</div>